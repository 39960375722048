@import 'ngx-toastr/toastr';

@import './assets/stylesheets/fonts.scss';


:root {
  --color-red: red;
  --color-black: #000;
  --color-white: #fff;
  --color-red-grey: rgb(215, 133, 133);
  --color-light-grey: rgb(115, 106, 106);
  --color-grey: rgb(215, 215, 215);
  --color-light-green: #b5c3b5;

  --background-color: var(--color-white);
  --color-alert-success: var(--color-light-green);
  --color-font-hover: var(--color-grey);
  --exhibit-border: 3px solid var(--color-black);

  --logo-height: 120px;
  --thumb-image-width: 40vw;
  --thumb-tablet-image-width: 30vw;
  --thumb-desktop-image-width: 20vw;
}

* {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: #000;
  font-size: 20px;
  font-family: 'Courier Prime', monospace;
}

hr {
  border-top: var(--exhibit-border);
}

h1 {
  font-size: 1.618rem;
  font-weight: 700;
  // font-style: bold;
  margin-block-start: 0;
  margin-block-end: 0;
}

h2 {
  font-size: 1.618rem;
  font-weight: 400;
  margin-block-start: 0;
  margin-block-end: 0;
}

h3 {
  font-size: 1rem;
  font-weight: 700;
  margin-block-start: 0;
  margin-block-end: 0;
}

h4 {
  font-size: .85rem;
  font-weight: 700;
  margin-block-start: 0;
  margin-block-end: 0;
}

p {
  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  line-height: 1.1;
}

button {
  font-family: 'Courier Prime', monospace;
  font-size: 1.2rem;
  font-weight: 700;
  font-style: bold;
  text-decoration: none;
  color: #000;
  border: none;
  background-color: transparent;
  padding: 0;

  &:hover {
    cursor: pointer;
  }
}

button:disabled {
  color: var(--color-grey);
}

button:hover {
  color: var(--color-font-hover);
}

a {
  text-decoration: none;
  color: #000;
  cursor: pointer;
}

a:hover {
  color: var(--color-font-hover);
}

ul.menu {
  list-style-type: none;
  padding: 0;
  margin-block-start: 0;
  margin-block-end: 0;

  li {
    padding: 0.7rem;
    border-bottom: 3px solid #000000;
    cursor: pointer;
  }
}


.formrow {
  margin-bottom: 1rem;

  &.secondary-action {
    padding-top: 1rem;
  }
}

.invalid-feedback {
  color: var(--color-red);
  font-size: 0.7rem;
  padding: .5rem .5rem;
}


.top {
  line-height: 0;
}

.account_items {
  display: block;
  text-align: left;
}

.account_item {
  width: 100%;
  padding: 1rem 0.7rem;
  // border-bottom: 3px solid #000000;
}

.account_item_sp {
  width: 100%;
  padding: 0;
  border-bottom: 3px solid #000000;
}

.account_item_sp:last-child {
  border-bottom: none;
}

.exhibits {
  display: flex;
  padding: 0.7rem 0.7rem 0.7rem 0;
  text-align: left;
  border-bottom: 3px solid #000000;
}

.exhibits:last-child {
  border-bottom: none;
}

.exhibit {
  padding: 0.7rem;
  text-align: left;
}

.exhibit_thumb {
  width: var(--thumb-desktop-image-width);
}

.img_thumb {
  width: var(--thumb-desktop-image-width);
}

.exhibit_content {
  width: 50vw;
  padding: 0.7rem;
}

.exhibit_content_large {
  width: 100%;
  padding: 1.5rem 0.7rem;
}

.exhibit_content_large_nop {
  width: 100%;
  padding: 2rem 0;
}

.img_large {
  max-height: 100vh;
  max-width: calc(100vw - calc(2*0.7rem));
}

.large {
  font-size: 1.68rem;
}

label,
legend {
  display: block;
  margin-bottom: .5rem;
}

input[type='color'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='email'],
input[type='month'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
input[type='week'],
input:not([type]),
textarea,
select {
  -webkit-appearance: none;
  outline: 0;
  background-color: transparent;
  border: 0.1rem solid var(--color-black);
  border-color: transparent;
  border-bottom: 0.1rem solid var(--color-light-grey);
  box-shadow: none;
  box-sizing: inherit;
  height: 2.8rem;
  padding: .6rem 1.0rem .7rem;
  width: 100%;
  font-size: 1rem;
  font-family: 'Courier Prime', monospace;
}

input[type='color']:focus,
input[type='date']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='email']:focus,
input[type='month']:focus,
input[type='number']:focus,
input[type='password']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='text']:focus,
input[type='url']:focus,
input[type='week']:focus,
input:not([type]):focus,
textarea:focus,
select:focus {
  border: 0.1rem solid #000000;
  outline: 0;
  font-size: 1rem;
  font-family: 'Courier Prime', monospace;
}

input[type='checkbox'],
input[type='radio'] {
  -ms-transform: scale(2);
  /* IE */
  -moz-transform: scale(2);
  /* FF */
  -webkit-transform: scale(2);
  /* Safari and Chrome */
  -o-transform: scale(2);
  /* Opera */
  transform: scale(2);
}

.no_border {
  border-bottom: none;
}

input.acordeon {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.accordeon-tabs {
  overflow: hidden;
}

.accordeon-tab {
  width: 100%;
  overflow: hidden;
  border-bottom: 3px solid var(--color-black);

  &-label {
    display: flex;
    justify-content: space-between;
    padding: 0.8rem 0rem 0rem 0.7rem;
    cursor: pointer;

    /* Icon */
    &:hover {
      color: var(--color-font-hover);
    }
  }

  &-content {
    display: none;
    padding: 0 0.7em;
  }

  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 0.7em;
    cursor: pointer;
  }
}

.padded-content {
  padding: 0.7em;
}

.bottom-border {
  border-bottom: var(--exhibit-border);
}

.legal-content {
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }

  h2 {
    font-size: 1.2rem;
    font-weight: 700;
  }

  p {
    margin-top: 0;
  }
}

// :checked
input:checked {
  ~.accordeon-tab-content {
    display: block;
  }
}

.toast-success {
  background-color: var(--color-alert-success);
  opacity: 100%;
  z-index: 2;
}

@media screen and (max-width: 70.0rem) {
  .exhibit_thumb {
    width: var(--thumb-tablet-image-width);
  }

  .img_thumb {
    width: var(--thumb-tablet-image-width);
  }
}

@media screen and (max-width: 41.0rem) {

  .exhibit_thumb {
    width: var(--thumb-image-width);
  }

  .img_thumb {
    width: var(--thumb-image-width);
  }

  .img_large {
    width: 92vw;
    height: auto;
  }

}